import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import Container from "react-bootstrap/Container";
import { isMobileOnly } from "react-device-detect";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Marzipano from "marzipano";
import { data } from "./data";
import ChangeOrientation from "./changeOrientation";
import useScreenOrientation from "react-hook-screen-orientation";

import "./pano.scss";

const viewerOpts = {
  controls: {
    mouseViewMode: "drag",
  },
};
const Pano = () => {
  const viewerCanvas = useRef(null);
  const infospot_1 = useRef(null);
  const infospot_2 = useRef(null);
  const infospot_3 = useRef(null);
  const linkspot = useRef(null);
  const breakPoint = 1500;
  const [viewer, setViewer] = useState(null);
  const [actualSceneIndex, setActualSceneIndex] = useState(0);
  const [actualInfoIndex, setActualInfoIndex] = useState(0);
  const [hotspotsList, setHotspotsList] = useState([]);
  const [readMoreHotspotIsOpen, setReadMoreHotspotIsOpen] = useState([]);
  const [displaySplashScreen, setDisplaySplashScreen] = useState(true);
  const [viewCoordinate, setViewCoordinate] = useState({ x: 0, y: 0, fov: 0 });
  const [
    multipleHotspotHelperArrowCoordinate,
    setMultipleHotspotHelperArrowCoordinate,
  ] = useState({});

  const [displayChangeOrientationScreen, setDisplayChangeOrientationScreen] =
    useState(false);
  const screenOrientation = useScreenOrientation();

  let isMobile =
    Math.min(window.screen.width, window.screen.height) < 712 &&
    navigator.userAgent.indexOf("Mobi") > -1;

  useEffect(() => {
    const updateDisplay = (e) => {
      let view = viewer?.view();

      setViewCoordinate({
        yaw: view?._params.yaw,
        pitch: view?._params.pitch,
        fov: view?._fov,
      });
    };
    if (viewerCanvas && viewerCanvas.current) {
      window.addEventListener("touchmove", updateDisplay);
      window.addEventListener("mousemove", updateDisplay);
    }
    return () => {};
    window.removeEventListener("touchmove", updateDisplay);
    window.removeEventListener("mousemove", updateDisplay);
  }, [viewCoordinate]);

  useEffect(() => {
    if (viewerCanvas === null) {
      throw TypeError(
        "Container cannot be null, or viewer will not be initialized"
      );
    }
    if (viewerCanvas && viewerCanvas.current && viewer === null) {
      // Create Scene
      setViewer(new Marzipano.Viewer(viewerCanvas.current, viewerOpts));
    }
  }, [viewer, viewerCanvas]);

  useEffect(() => {
    if (viewer) {
      let panoScene = { data: data.scenes[actualSceneIndex] };
      const {
        id,
        initialViewParameters,
        levels,
        linkHotspots,
        infoHotspots,
        urlPrefix,
      } = panoScene.data;
      const source = Marzipano.ImageUrlSource.fromString(
        `${urlPrefix}${id}/{f}.jpg`,
        {
          cubeMapPreviewUrl: `${urlPrefix}${id}/f.jpg`,
        }
      );
      let limiter = Marzipano.util.compose(
        Marzipano.RectilinearView.limit.hfov(1000, (100 * Math.PI) / 160),
        Marzipano.RectilinearView.limit.pitch(-1, 0.6)
      );

      if (window.screen.width < breakPoint) {
        limiter = Marzipano.util.compose(
          Marzipano.RectilinearView.limit.hfov(1000, (100 * Math.PI) / 180),
          Marzipano.RectilinearView.limit.pitch(-1, 0.2)
        );
      }

      if (actualSceneIndex === 9 || actualSceneIndex === 10) {
        limiter = Marzipano.util.compose(
          Marzipano.RectilinearView.limit.hfov(1000, (100 * Math.PI) / 155),
          Marzipano.RectilinearView.limit.pitch(-0.1, -0.1),
          Marzipano.RectilinearView.limit.yaw(-2.2, -2.2)
        );
      }

      let view = new Marzipano.RectilinearView(initialViewParameters, limiter);

      panoScene["view"] = view;

      let geometry = new Marzipano.CubeGeometry(levels);

      const scene = viewer.createScene({
        source: source,
        geometry: geometry,
        view: view,
        switchTo: 100,
      });

      panoScene["scene"] = scene;

      const infoHotspotsList = infoHotspots?.map((hotspot, key) => {
        let position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
        if (window.screen.width < breakPoint) {
          if (actualSceneIndex === 0) {
            position = { yaw: hotspot.yaw + 0.2, pitch: hotspot.pitch - 0.5 };
          } else if (actualSceneIndex === 6) {
            position = { yaw: hotspot.yaw - 0.2, pitch: hotspot.pitch - 0.6 };
          } else {
            position = { yaw: hotspot.yaw, pitch: hotspot.pitch - 0.2 };
          }
        }
        if (
          window.screen.width < breakPoint &&
          actualSceneIndex === 6 &&
          key === 3
        ) {
          position = { yaw: hotspot.yaw, pitch: hotspot.pitch - 0.1 };
        }

        setHotspotsList((oldHotSpotsList) => {
          return [
            ...oldHotSpotsList,
            {
              title: hotspot.title,
              text: hotspot.text,
              text2: hotspot.text2,
              className: hotspot.className,
              characterPictureUrl: hotspot.characterPictureUrl,
              display: hotspot.className.includes("read-more") ? false : true,
              type: "info",
              nextAction: hotspot.nextAction,
              icon: hotspot.icon,
              position: position,
            },
          ];
        });
        if (hotspot.className.includes("read-more")) {
          setReadMoreHotspotIsOpen((oldValues) => {
            let newValues = [...oldValues];
            newValues.push({
              className: hotspot.className,
              isOpen: false,
              isOpened: false,
            });

            return newValues;
          });
        }
        return position;
      });

      if (infoHotspotsList?.length > 0) {
        scene
          .hotspotContainer()
          .createHotspot(infospot_1.current, infoHotspotsList[0]);
        scene
          .hotspotContainer()
          .createHotspot(infospot_2.current, infoHotspotsList[1]);
        scene
          .hotspotContainer()
          .createHotspot(
            infospot_3.current,
            infoHotspotsList[2] ? infoHotspotsList[2] : infoHotspotsList[1]
          );
      }
      linkHotspots?.forEach(function (hotspot, key) {
        let position = { yaw: hotspot.yaw, pitch: hotspot.pitch };

        setHotspotsList((oldHotSpotsList) => {
          return [
            ...oldHotSpotsList,
            {
              title: hotspot.title,
              text: hotspot.text,
              className: hotspot.className,
              display: hotspot.display,
              type: "link",
              nextAction: hotspot.nextAction,
              icon: hotspot.icon,
              position: position,
            },
          ];
        });
        scene.hotspotContainer().createHotspot(linkspot.current, position);
      });

      panoScene.scene.switchTo();

      if (panoScene.data.switchToNextScene) {
        setTimeout(() => {
          goToNextScene();
        }, 100);
      }
    }
  }, [viewer, actualSceneIndex]);

  const executeFunctionFromJson = (fn, params) => {
    switch (fn) {
      case "hideInfoHotSpot":
        hideInfoHotSpot(params);
        break;

      case "goToNextScene":
        goToNextScene();
        break;

      default:
        break;
    }
  };

  const hideInfoHotSpot = (className) => {
    setActualInfoIndex(actualInfoIndex + 1);

    setHotspotsList((oldHotSpotsList) => {
      const newHotSpotslist = [...oldHotSpotsList];

      newHotSpotslist.forEach((hotspot, key) => {
        if (hotspot.className === className) {
          hotspot.display = true;
        }
      });

      return newHotSpotslist;
    });
  };

  const goToNextScene = () => {
    setHotspotsList([]);
    setReadMoreHotspotIsOpen([]);
    setActualSceneIndex(actualSceneIndex + 1);

    setActualInfoIndex(0);
    setViewCoordinate({});
  };

  const readMoreToggleAction = (className) => {
    const newValues = [...readMoreHotspotIsOpen];
    const allReadMoreHotSpotAreRead = readMoreHotspotIsOpen.every(
      (hotspot) => hotspot.isOpened === true
    );
    if (
      hotspotsList[actualInfoIndex + 1]?.className.includes("read-more") &&
      !allReadMoreHotSpotAreRead
    ) {
      const findHotspot = readMoreHotspotIsOpen.filter((hotspot) => {
        return hotspot.className !== className && !hotspot.isOpened;
      });

      if (findHotspot.length > 0) {
        setMultipleHotspotHelperArrowCoordinate((oldValues) => {
          return hotspotsList.find((hotspot) => {
            return hotspot.className === findHotspot[0].className;
          })?.position;
        });
      }
    } else {
      setMultipleHotspotHelperArrowCoordinate({});
    }

    newValues.forEach((element) => {
      if (element.className === className) {
        element.isOpen = !element.isOpen;
        element.isOpened = true;

        if (!element.isOpen) {
          if (actualSceneIndex === 8) {
            goToNextScene();
          }
        }
      }
    });
    setReadMoreHotspotIsOpen((oldValues) => (oldValues = newValues));
  };

  const displayReadMoreHotSpot = useCallback(
    (className) => {
      const findClassName = readMoreHotspotIsOpen.find(
        (el) => el.className === className
      );

      if (findClassName?.isOpen) {
        return "inherit";
      }
    },
    [readMoreHotspotIsOpen]
  );

  const renderInfoHotspot = (hotspot) => {
    const hideHostpot =
      hotspot.className.includes("link") ||
      hotspot.className.includes("dialog") ||
      !hotspot.display;

    if (hotspot) {
      return (
        <Container
          className={`hotspot-info-container ${hotspot.className}`}
          style={{ display: hideHostpot ? "none" : "inherit" }}
        >
          {hotspot?.className.includes("info") && (
            <Row>
              <Col className="close-icon-container" sm="12">
                <button
                  className="close-icon"
                  onClick={() => {
                    executeFunctionFromJson(
                      hotspot.nextAction,
                      hotspot.className
                    );
                  }}
                >
                  <img
                    src="/img/icons/close-cross-icon.png"
                    alt="close-button"
                  />
                </button>
              </Col>
              <Col sm="12">
                <h3>{hotspot?.title}</h3>
              </Col>
            </Row>
          )}
          {!hotspot?.className.includes("dialog") && (
            <Row>
              <Col
                className={hotspot.text2 ? "hotspot-tutoriel-first-text" : ""}
                sm={hotspot.text2 ? "11" : "12"}
              >
                <p>{hotspot?.text}</p>
              </Col>
              {hotspot.text2 && (
                <Col
                  sm={hotspot.text2 ? "11" : "12"}
                  className={
                    hotspot.text2 ? "hotspot-tutoriel-second-text" : ""
                  }
                >
                  <p>{hotspot?.text2}</p>
                </Col>
              )}
            </Row>
          )}
        </Container>
      );
    }
  };

  const displayHotspots = useMemo(() => {
    const readMoreHotspots = hotspotsList.filter((hotspot) => {
      return hotspot.className.includes("read-more");
    });
    const readMoreIcon1 =
      hotspotsList[0]?.isOpen === true
        ? readMoreHotspots[0]?.icon.opened
        : readMoreHotspots[0]?.icon.closed;
    const readMoreIcon2 =
      hotspotsList[1]?.isOpen === true
        ? readMoreHotspots[1]?.icon.opened
        : readMoreHotspots[1]?.icon.closed;

    return (
      <>
        <Container
          className={`info-hotspot ${hotspotsList[actualInfoIndex]?.className}`}
          ref={infospot_1}
        >
          {hotspotsList.length > 0 &&
          !hotspotsList[actualInfoIndex].className.includes("read-more") ? (
            renderInfoHotspot(hotspotsList[actualInfoIndex])
          ) : (
            <Col style={{ display: "flex", flexDirection: "column" }}>
              <div
                sm="12"
                className="readmore-main-container"
                style={{
                  visibility: readMoreHotspotIsOpen[0]?.isOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <p
                  style={{
                    display: displayReadMoreHotSpot(
                      readMoreHotspots[0]?.className
                    ),
                  }}
                >
                  {" "}
                  {readMoreHotspots[0]?.text}
                </p>
              </div>
              <button
                className="readmore-btn"
                onClick={(e) => {
                  readMoreToggleAction(readMoreHotspots[0]?.className);
                }}
              >
                <img
                  className="icon-btn"
                  src={`/img/icons/${readMoreIcon1}`}
                  alt={readMoreIcon1}
                />
              </button>
            </Col>
          )}
        </Container>
        <Container
          className={`info-hotspot ${readMoreHotspots[0]?.className}`}
          ref={infospot_2}
        >
          {readMoreHotspots.length === 0 && (
            <Col style={{ display: "flex", flexDirection: "column" }}>
              <div
                sm="12"
                className="readmore-main-container"
                style={{
                  visibility: readMoreHotspotIsOpen[0]?.isOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <p
                  style={{
                    display: displayReadMoreHotSpot(
                      readMoreHotspots[0]?.className
                    ),
                  }}
                >
                  {readMoreHotspots[0]?.text}
                </p>
              </div>
              {readMoreIcon1 && (
                <button
                  className="readmore-btn"
                  onClick={(e) => {
                    readMoreToggleAction(readMoreHotspots[0]?.className);
                  }}
                >
                  <img
                    className="icon-btn"
                    src={`/img/icons/${readMoreIcon1}`}
                    alt={readMoreIcon1}
                  />
                </button>
              )}
            </Col>
          )}
        </Container>
        <Container className="info-hotspot" ref={infospot_3}>
          {readMoreHotspots.length > 1 && (
            <Col style={{ display: "flex", flexDirection: "column" }}>
              <div
                sm="12"
                className="readmore-main-container"
                style={{
                  visibility: readMoreHotspotIsOpen[1]?.isOpen
                    ? "visible"
                    : "hidden",
                }}
              >
                <p
                  style={{
                    display: displayReadMoreHotSpot(
                      readMoreHotspots[1]?.className
                    ),
                  }}
                >
                  {readMoreHotspots[1]?.text}
                </p>
              </div>
              {readMoreHotspots[1] && (
                <button
                  className="readmore-btn"
                  onClick={(e) => {
                    readMoreToggleAction(readMoreHotspots[1]?.className);
                  }}
                >
                  <img
                    className="icon-btn"
                    src={`/img/icons/${readMoreIcon2}`}
                    alt={readMoreIcon2}
                  />
                </button>
              )}
            </Col>
          )}
        </Container>
      </>
    );
  }, [readMoreHotspotIsOpen, actualInfoIndex, hotspotsList, actualSceneIndex]);

  const displayLinkHotspot = useMemo(() => {
    const findLinkHotspot = hotspotsList.find((hotspot) =>
      hotspot.className.includes("link")
    );
    const allReadMoreHotSpotAreRead = readMoreHotspotIsOpen.every(
      (hotspot) => hotspot.isOpened === true
    );
    const hotspotListLengthWithoutPOI = hotspotsList.filter(
      (hotspot) => !hotspot.className.includes("read-more")
    ).length;
    const lastHotspot = hotspotListLengthWithoutPOI === actualInfoIndex + 1;

    return (
      <button
        className="link-hotspot"
        ref={linkspot}
        onClick={() => {
          executeFunctionFromJson(
            findLinkHotspot?.nextAction,
            hotspotsList[actualInfoIndex]?.className
          );
        }}
      >
        {findLinkHotspot && allReadMoreHotSpotAreRead && lastHotspot && (
          <div className="link-hotspot-container">
            <img
              className="link-icon-btn"
              src={`/img/icons/${findLinkHotspot.icon}`}
              alt="link-arrow"
            />
          </div>
        )}
      </button>
    );
  }, [hotspotsList, readMoreHotspotIsOpen]);

  const displayDialog = useMemo(() => {
    if (
      hotspotsList.length > 0 &&
      hotspotsList[actualInfoIndex].className.includes("dialog")
    ) {
      return (
        <Container className={`hotspot-info-container  dialog-container`}>
          <Row>
            <Col className="character-illustration-container" sm="1">
              <img
                className="character-illustration"
                src={`/img/characters/${hotspotsList[actualInfoIndex].characterPictureUrl}`}
                alt="character-illustration"
              />
            </Col>
            <Col sm="10">
              <p className="dialog-paragraph">
                {hotspotsList[actualInfoIndex]?.text}
              </p>
            </Col>
            <Col className="dialog-button" sm="1">
              <button
                onClick={() => {
                  executeFunctionFromJson(
                    hotspotsList[actualInfoIndex]?.nextAction,
                    hotspotsList[actualInfoIndex]?.className
                  );
                }}
              >
                <img
                  src={`/img/icons/${hotspotsList[actualInfoIndex]?.icon}`}
                  alt="next-button"
                />
              </button>
            </Col>
          </Row>
        </Container>
      );
    }
  }, [actualInfoIndex, hotspotsList]);

  const onDisplaySplashScreenClick = () => {
    setTimeout(() => {
      setDisplaySplashScreen(false);
    }, 500);
  };

  const restartGame = () => {
    setDisplaySplashScreen(true);
    setActualSceneIndex(0);
    setActualInfoIndex(0);
    setHotspotsList([]);
    setReadMoreHotspotIsOpen([]);
    setActualInfoIndex(0);
  };
  function checkOr() {
    switch (window.orientation) {
      case -90:
      case 90:
        setDisplayChangeOrientationScreen(false);
        break;
      default:
        if (
          !isMobile &&
          (window.screen.width > 900 || window.screen.height > 900) &&
          window.screen.width < window.screen.height
        ) {
          setDisplayChangeOrientationScreen(true);
        }
        break;
    }
  }

  useEffect(() => {
    checkOr();
  }, []);

  window.addEventListener("orientationchange", checkOr);

  const displayScreenMobileTemplate = useMemo(() => {
    if (isMobile) {
      const className = screenOrientation?.includes("portrait")
        ? "mobileScreen-btn"
        : "mobileScreen-btn-landscape";
      return (
        <div
          className="mobileScreen"
          style={{
            display: isMobile ? "block" : "none",
            position: "absolute",
          }}
        >
          <button className={className}>
            <a
              target="_blank"
              href="https://www.museeliberation-leclerc-moulin.paris.fr/decouvrez-le-premier-serious-game-en-famille-du-musee"
              rel="noreferrer"
            >
              <img
                className="mobileScreen-btn-picture"
                src="/img/composants/linkToSite.png"
              />
            </a>
          </button>
        </div>
      );
    }
  }, [isMobileOnly, screenOrientation, isMobile]);

  const displayhelperArrow = useMemo(() => {
    const allReadMoreHotSpotAreRead = readMoreHotspotIsOpen.every(
      (hotspot) => hotspot.isOpened === true
    );

    let currenthotspot = allReadMoreHotSpotAreRead
      ? hotspotsList[hotspotsList.length - 1]?.position
      : multipleHotspotHelperArrowCoordinate.yaw
      ? multipleHotspotHelperArrowCoordinate
      : hotspotsList[actualInfoIndex]?.position;

    if (
      readMoreHotspotIsOpen.length > 0 &&
      currenthotspot !== 0 &&
      !hotspotsList[actualInfoIndex]?.className.includes("dialog")
    ) {
      const calc = viewCoordinate?.yaw - currenthotspot?.yaw;
      let calculateleftPosition = -0.55;
      let calculateRightPosition = 0.8;
      let additionnalLeftValue = null;
      let additionnalRightValue = null;

      if (actualSceneIndex === 6) {
        if (allReadMoreHotSpotAreRead) {
          calculateleftPosition = 5.45;
          additionnalLeftValue = 3.3;
          calculateRightPosition = 0.6;
          additionnalRightValue = 3.3;
        } else {
          calculateleftPosition = -0.6;
        }
      } else if (actualSceneIndex === 5) {
        if (allReadMoreHotSpotAreRead) {
          calculateleftPosition = -0.65;
          additionnalLeftValue = -3.3;
          calculateRightPosition = -5.5;
          additionnalRightValue = -3.3;
        }
      }
      if (readMoreHotspotIsOpen.every((hotspot) => !hotspot.isOpen)) {
        if (calc < calculateleftPosition) {
          if (!additionnalLeftValue || calc > additionnalLeftValue) {
            return (
              <div
                style={{
                  position: "absolute",
                  right: "30vw",
                  top: "50vh",
                  paddingTop: "13px",
                  width: "70px",
                  height: "70px",
                  paddingRight: "6px",
                }}
                className="helperArrowContainer"
              >
                <img
                  className="helperArrow"
                  src="/img/icons/arrow-right.svg"
                  alt="left arrow"
                />
              </div>
            );
          }
        }
        if (calc > calculateRightPosition && currenthotspot !== 0) {
          if (!additionnalRightValue || calc < additionnalRightValue) {
            return (
              <div
                style={{
                  position: "absolute",
                  left: "30vw",
                  top: "50vh",
                  width: "70px",
                  height: "70px",
                  paddingTop: "3px",
                  paddingLeft: "6px",
                }}
                className="helperArrowContainer"
              >
                <img
                  className="helperArrow"
                  src="/img/icons/arrow-left.svg"
                  alt="left arrow"
                />
              </div>
            );
          }
        }
      }
    }
  }, [actualInfoIndex, viewCoordinate, readMoreHotspotIsOpen]);

  return (
    <>
      <div
        className="splashscreen"
        style={{
          display:
            displaySplashScreen &&
            (window.screen.width > 900 ||
              (window.screen.height > 900 && !isMobile))
              ? "block"
              : "none",
          filter: displayChangeOrientationScreen ? "blur(4px)" : "none",
        }}
      >
        <button
          onClick={(e) => {
            onDisplaySplashScreenClick();
          }}
          className="splashscreen-btn"
        />
      </div>
      {displayScreenMobileTemplate}
      <div
        className="pano"
        style={{
          visibility: !displaySplashScreen ? "visible" : "hidden",
          filter: displayChangeOrientationScreen ? "blur(4px)" : "none",
        }}
        ref={viewerCanvas}
      />
      {displayHotspots}
      {displayLinkHotspot}
      {displayDialog}
      {displayhelperArrow}
      {actualSceneIndex === 10 && (
        <div className="finalSceneBtnContainer">
          <div className="hotspot-info-container final-scene-container">
            <p>
              Viens poursuivre l’expérience au musée de la Libération de Paris –
              musée du général Leclerc – musée Jean Moulin avec le jeu Mission
              Rol-Tanguy au sein de l’abri souterrain !
            </p>
          </div>
          <a
            target="_blank"
            href="https://www.museeliberation-leclerc-moulin.paris.fr/decouvrez-le-premier-serious-game-en-famille-du-musee"
            rel="noreferrer"
          >
            <button className="linkToMuseum"></button>
          </a>
          <button
            onClick={(e) => {
              restartGame();
            }}
            className="restartTour"
          />
        </div>
      )}
      {displayChangeOrientationScreen && <ChangeOrientation />}
    </>
  );
};

export default Pano;
