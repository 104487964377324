import Pano from './components/Pano'
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <div className="App">
      <Pano/>
    </div>
  );
}

export default App;
