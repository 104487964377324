import React from "react";
import "./changeOrientation.scss";

const ChangeOrientation = () => {
  return (
    <>
      <div className="change-rotation-container">
        <div className="change-rotation-icon-container">
          <div className="phone"></div>
          <p className="change-rotation-label">
            Veuillez tourner votre appareil
          </p>
        </div>
      </div>
    </>
  );
};

export default ChangeOrientation;
