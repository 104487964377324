import ressources from "./scenesList.json";

const scenes = ressources.scenesList.map((scene) => {
  return {
    id: scene.name,
    name: scene.name,
    urlPrefix: "/img/scenes/cubemap/",
    levels: [
      {
        tileSize: 256,
        size: 256,
        fallbackOnly: true,
      },
      {
        tileSize: 512,
        size: 512,
      },
      {
        tileSize: 1024,
        size: 1024,
      },
      {
        tileSize: 2048,
        size: 2048,
      },
      {
        tileSize: 4096,
        size: 4096,
      },
    ],
    faceSize: 4096,
    initialViewParameters: scene.initialViewParameter,
    infoHotspots: scene.infoHotspots,
    linkHotspots: scene.linkHotspots,
    switchToNextScene: scene.switchToNextScene,
  };
});

export const data = {
  scenes,
  name: "Rol tanguy",
  settings: {
    mouseViewMode: "drag",
    autorotateEnabled: true,
    fullscreenButton: true,
    viewControlButtons: true,
  },
};
